.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.dot {
  color: rgb(29, 128, 159);
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  text-shadow: none;
  transition: opacity 1s ease,
      text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.dotactive {
  
  color: rgb(220, 53, 69);
  opacity: 1;
  text-shadow: 0 0px 8px;
}

.dotdeactivated {
  color: gray;
  cursor: default;
  opacity: .4;
}

.divider {
  color: rgb(29, 128, 159);
  text-shadow: none;
  transition: opacity 1s ease,
      text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.divideractive {
  color: rgb(220, 53, 69);
  opacity: 1;
}

.dividerdeactivated {
  color: gray;
  cursor: default;
  opacity: .4;
}

.callout .accordion-header {
  max-width: 100%;
}

h1 {
  font-size: 1.7rem;
}

.masthead_calculator {
  height: 100%;
  margin: 0;
  padding: 0;
}

.masthead h1,
.simple_header h1 {
  font-size: 6vw;
}

@media screen and (min-width: 768px) {
  .masthead h1,
  .simple_header h1 {
    font-size: 5vw;
  }
}

@media screen and (min-width: 1200px) {
  .masthead h1,
  .simple_header h1 {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1400px) {
  .masthead h1,
  .simple_header h1 {
    font-size: 3vw;
  }
}

.page_title {
  font-size: 3rem;
  display:block;
}

.page_title_background {
  background-color: rgb(255, 255, 255, 0.5);
}

.header_card {
  font-size: 1.5rem;
}

.link_no_underlining:link,
.link_no_underlining:visited {
  text-decoration: none;
}

.accordion_class,
.accordion-button,
.accordion-body,
.accordion-button:not(.collapsed) {
  background-color: rgb(248, 212, 114);
}

.content-section {
  background-color: #f8f9fa;
}

.simple_header {
  background-color: rgb(255, 210, 52);
}

.header_background {
  background-color: #ffffff80;
}

.wizard_background {
  background-color: rgb(255, 255, 255, 0.7) !important;
}

.large_number {
  color: rgb(255, 210, 52);
  font-size: 7rem;
}

.onsite_card {
  background-color:rgb(29, 128, 159);
  border-color: rgb(29, 128, 159);
  color: white;
}

.onsite_card h2 {
  font-size: 1.5rem;
}

#guide_button {
  color: #fff;
  background-color: #beaa90;
  border-color: #beaa90;
}
#guide_button:hover {
  color: #fff;
  background-color: #a08f79;
  border-color: #a08f79;
}

.progressbar-calculator {
  height: 5px;
}

.progressbar-calculator .progress-bar {
  background-color: #959595;
}

hr.divider .ms-2
{ 
  margin: 0;
} 

.calc_nav {
  width: 100%;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.calc-row {
  width:100%;
  margin: 0;
  padding: 0;
}


.wizard_class {
}